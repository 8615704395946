<template>
    <div class="filter-items">
        <!-- (SLOT is necesary for ViewConfigForm) -->
        <slot></slot>

        <div class="filter">
            <label>{{ $t('user.filters.search_by') }}</label>
            <!-- SITES (UBICACIONES) -->
            <v-select
                :value="currentFilters.sites"
                :class="{
                    filtered: currentFilters.sites,
                    changed:
                        currentFilters.sites !== undefined &&
                        activeFilters.sites !== undefined &&
                        JSON.stringify(currentFilters.sites) != JSON.stringify(activeFilters.sites)
                }"
                hide-details
                class="select"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'sites', value: value })
                    }
                "
                v-if="Object.values(optionsSites)"
                :items="Object.values(optionsSites)"
                item-text="name"
                item-value="value"
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="$t('assets.filter.filter_by')"
            ></v-select>
        </div>

        <!-- LOCATIONS -->
        <div class="sites filter" v-if="currentFilters.sites == 'locations'">
            <label class="description">{{ $t('user.filters.available_sites') }}</label>
            <v-autocomplete
                :value="currentFilters.locations"
                :class="{
                    filtered:
                        currentFilters.locations && 
                        typeof currentFilters.locations !== 'undefined' && 
                        Object.values(currentFilters.locations).length > 0,
                    changed:
                        JSON.stringify(currentFilters.locations) != JSON.stringify(activeFilters.locations) &&
                        currentFilters.locations &&
                        Object.values(currentFilters.locations).length > 0
                }"
                hide-details
                class="select"
                @input="
                    (locationsInputValue) => {
                        $emit('inputChange', { inputName: 'locations', value: locationsInputValue })
                    }
                "
                v-if="Object.values(optionsLocations)"
                :items="Object.values(optionsLocations)"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="currentFilters.locations ? '' : $t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags
                v-if="currentFilters.locations"
                :tags="currentFilters.locations"
                :backImage="'location_inactive_s30.svg'"
                :nameTag="'name'"
                value="id"
                :removable="true"
            ></Tags>
        </div>
        <!-- GROUPS -->
        <div class="custom filter" v-else-if="currentFilters.sites == 'groups'">
            <label class="description">{{ $t('user.filters.available_territories') }}</label>
            <v-autocomplete
                :value="currentFilters.groups"
                :class="{
                    filtered:
                        currentFilters.groups &&
                        typeof currentFilters.groups !== 'undefined' &&
                        currentFilters.groups &&
                        Object.values(currentFilters.groups).length > 0,
                    changed: JSON.stringify(currentFilters.groups) != JSON.stringify(activeFilters.groups)
                }"
                hide-details
                class="select"
                @input="
                    (inputValue) => {
                        $emit('inputChange', { inputName: 'groups', value: inputValue })
                    }
                "
                v-if="Object.values(optionsGroups)"
                :items="Object.values(optionsGroups)"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="currentFilters.groups ? '' : $t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags v-if="currentFilters.groups" :tags="currentFilters.groups" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>

        <!-- TEMPLATE -->
        <div class="filter">
            <label>{{ $t('supervise.issues.template_issues') }}</label>
            <v-autocomplete :class="{
                filtered: currentFilters.template && Object.values(currentFilters.template).length > 0,
                changed: JSON.stringify(currentFilters.template) != JSON.stringify(activeFilters.template)
            }" :items="Object.values(optionsTemplates)" :value="currentFilters.template" @input="(value) => {
                    $emit('inputChange', { inputName: 'template', value: value })
                }
                " :placeholder="$t('assets.filter.filter_by')" :no-data-text="$t('supervise.filter.no_results')"
                hide-details class="select" item-text="name" item-value="id" multiple attach dense return-object>
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>

                <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters class="options" v-html="item.name"> </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-autocomplete>
            <Tags :tags="currentFilters.template" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>

        <!-- STATUS -->
        <div class="filter">
            <label>{{ $t('supervise.issues.table_headers.state') }}</label>
            <v-autocomplete :class="{
                filtered: currentFilters.status && Object.values(currentFilters.status).length > 0,
                changed: JSON.stringify(currentFilters.status) != JSON.stringify(activeFilters.status)
            }" :items="optionsStates" :value="currentFilters.status" @input="(value) => {
                    $emit('inputChange', { inputName: 'status', value: value })
                }
                " :placeholder="$t('assets.filter.filter_by')" :no-data-text="$t('supervise.filter.no_results')"
                hide-details class="select" item-text="name" item-value="id" multiple attach dense return-object>
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags :tags="currentFilters.status" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>

        <div class="filter">
            <label>{{ $t('template.general.issue_title_option') }}</label>
            <input
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'issueTitle', value: value.target.value })
                    }
                "
                :class="{
                    filtered: currentFilters.issueTitle && Object.values(currentFilters.issueTitle).length > 0,
                    changed: JSON.stringify(currentFilters.issueTitle) != JSON.stringify(activeFilters.issueTitle)
                }"
                type="text"
                hide-details
                class="input"
                v-model="currentFilters.issueTitle"
            />
            <!-- <Tags :tags="currentFilters.template" :nameTag="'name'" value="id" :removable="true"></Tags> -->
        </div>
        <div class="filter">
            <label>ID (#)</label>
            <input
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'issueId', value: value.target.value })
                    }
                "
                :class="{
                    filtered: currentFilters.issueId && Object.values(currentFilters.issueId).length > 0,
                    changed: JSON.stringify(currentFilters.issueId) != JSON.stringify(activeFilters.issueId)
                }"
                type="text"
                hide-details
                class="input"
                v-model="currentFilters.issueId"
            />
            <!-- <Tags :tags="currentFilters.template" :nameTag="'name'" value="id" :removable="true"></Tags> -->
        </div>

        <!-- DATE CREATED -->
        <div class="filter date">
            <label>{{ $t('supervise.issues.table_headers.date') }}</label>
            <v-select :class="{
                filtered: currentFilters.type_date,
                changed: JSON.stringify(currentFilters.type_date) != JSON.stringify(activeFilters.type_date)
            }" :items="optionsDate" :value="currentFilters.type_date" @input="(value) => {
                    $emit('inputChange', { inputName: 'type_date', value: value })
                }
                " hide-details class="select" item-text="name" item-value="value" dense
                :no-data-text="$t('supervise.filter.no_results')"></v-select>

            <!-- CUSTOM DATE CREATED-->
            <div class="custom" v-if="currentFilters.type_date == 'custom'">
                <!-- START DATE -->
                <div class="description">{{ $t('assets.filter.from') }}:</div>
                <input class="date-input start" :value="currentFilters.start_date" @input="(event) => {
                    $emit('inputChange', { inputName: 'start_date', value: event.target.value })
                }
                " :max="currentFilters.end_date" type="date" />
                <!-- :max="calendars.maxCreatedDate" -->

                <div class="separator"></div>
                <!-- END DATE -->
                <div class="description">{{ $t('assets.filter.to') }}:</div>
                <input class="date-input end" :value="currentFilters.end_date" @input="(event) => {
                    $emit('inputChange', { inputName: 'end_date', value: event.target.value })
                }
                " :min="currentFilters.start_date" type="date" />
                <!-- :min="calendars.minCreatedDate" -->
            </div>
        </div>

        <!-- DATE ENDED-->
        <div class="filter date">
            <label>{{ $t('supervise.issues.table_headers.date_end') }}</label>
            <v-select :class="{
                filtered: currentFilters.complete_date,
                changed: JSON.stringify(currentFilters.complete_date) != JSON.stringify(activeFilters.complete_date)
            }" :items="optionsDateEnd" :value="currentFilters.complete_date" @input="(value) => {
                    $emit('inputChange', { inputName: 'complete_date', value: value })
                }
                " :placeholder="$t('assets.filter.filter_by')" :no-data-text="$t('supervise.filter.no_results')"
                hide-details class="select" item-text="name" item-value="value" dense></v-select>

            <!-- CUSTOM COMPLETE DATE -->
            <div class="custom" v-if="currentFilters.complete_date == 'custom'">
                <!-- DATE START -->
                <div class="description">{{ $t('assets.filter.from') }}:</div>
                <input class="date-input start" :value="currentFilters.complete_date_start" @input="(event) => {
                    $emit('inputChange', { inputName: 'complete_date_start', value: event.target.value })
                }
                " :min="currentFilters.start_date" :max="currentFilters.complete_date_end" type="date" />

                <!-- :min="calendars.minCreatedDate"
                    :max="calendars.maxCompletedDate" -->

                <div class="separator"></div>

                <!-- DATE END -->
                <div class="description">{{ $t('assets.filter.to') }}:</div>
                <input class="date-input end" :value="currentFilters.complete_date_end" @input="(event) => {
                    $emit('inputChange', { inputName: 'complete_date_end', value: event.target.value })
                }
                " :min="currentFilters.complete_date_start" type="date" />

                <!-- :min="calendars.minCompletedDate" -->
            </div>
        </div>

        <!-- CATEGORY -->
        <!-- <div class="filter">
            <label>{{ $t('supervise.issues.table_headers.categories') }}</label>
            <v-autocomplete
                :class="{
                    filtered: currentFilters.category && Object.values(currentFilters.category).length > 0,
                    changed: JSON.stringify(currentFilters.category) != JSON.stringify(activeFilters.category)
                }"
                :items="Object.values(optionsCategories)"
                :value="currentFilters.category"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'category', value: value })
                    }
                "
                :placeholder="$t('assets.filter.filter_by')"
                :no-data-text="$t('supervise.filter.no_results')"
                class="select"
                item-text="name"
                item-value="id"
                hide-details
                multiple
                dense
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags :tags="currentFilters.category" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div> -->

    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    name: 'SuperviseIncidentsFilterInputs',
    components: { Tags },
    props: {
        currentFilters: { type: Object },
        activeFilters: { type: Object }
    },
    data() {
        return {}
    },
    computed: {
        optionsLocations() {
            return this.$store.getters['filterLocations/getFilterLocation']()
        },
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
        optionsTemplates() {
            var templates = Object.values(JSON.parse(JSON.stringify(this.$store.getters['issues/getFilterTemplate']())))

            // add inactive at final name of the template
            if (templates) {
                for (var i = 0; i < templates.length; i++) {
                    if (templates[i].status == '-2') {
                        templates[i].name = templates[i].name + ' <span class="inactive-card">' + this.$t('template.inactive') + '</span>'
                    }
                }
            }
            return templates
        },
        optionsStates() {
            const arrFilter = Object.values(this.$store.getters['issues/getFilterStates']()).sort((status1, status2) => (status1.type > status2.type ? 1 : -1))
            return arrFilter
        },
        optionsCategories() {
            return this.$store.getters['issues/getFilterCategories']()
        },
        optionsDateEnd() {
            return [
                { value: null, name: '-' },
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
        optionsSites() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                {
                    value: 'groups',
                    name: i18n.t('user.filters.territories')
                }
            ]
        },
        optionsGroups() {
            return this.$store.getters['filterLocations/getFilterGroups']()
        },
    },
    methods: {}
}
</script>

<style lang="scss" scoped></style>
