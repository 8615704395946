<template>
    <div class="filter-items">
        <!-- (SLOT is necesary for ViewConfigForm) -->
        <slot></slot>

        <div class="filter">
            <label>{{ $t('user.filters.search_by') }}</label>
            <!-- SITES (UBICACIONES) -->
            <v-select
                :value="currentFilters.sites"
                :class="{
                    filtered: currentFilters.sites,
                    changed:
                        currentFilters.sites !== undefined &&
                        activeFilters.sites !== undefined &&
                        JSON.stringify(currentFilters.sites) != JSON.stringify(activeFilters.sites)
                }"
                hide-details
                class="select"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'sites', value: value })
                    }
                "
                v-if="Object.values(optionsSites)"
                :items="Object.values(optionsSites)"
                item-text="name"
                item-value="value"
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="$t('assets.filter.filter_by')"
            ></v-select>
        </div>

        <!-- LOCATIONS -->
        <div class="sites filter" v-if="currentFilters.sites == 'locations'">
            <label class="description">{{ $t('user.filters.available_sites') }}</label>
            <v-autocomplete
                :value="currentFilters.locations"
                :class="{
                    filtered: currentFilters.locations && typeof currentFilters.locations !== 'undefined' && Object.values(currentFilters.locations).length > 0,
                    changed:
                        JSON.stringify(currentFilters.locations) != JSON.stringify(activeFilters.locations) &&
                        currentFilters.locations &&
                        Object.values(currentFilters.locations).length > 0
                }"
                hide-details
                class="select"
                @input="
                    (locationsInputValue) => {
                        $emit('inputChange', { inputName: 'locations', value: locationsInputValue })
                    }
                "
                v-if="Object.values(optionsLocations)"
                :items="Object.values(optionsLocations)"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="currentFilters.locations ? '' : $t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags
                v-if="currentFilters.locations"
                :tags="currentFilters.locations"
                :backImage="'location_inactive_s30.svg'"
                :nameTag="'name'"
                value="id"
                :removable="true"
            ></Tags>
        </div>
        <!-- GROUPS -->
        <div class="custom filter" v-else-if="currentFilters.sites == 'groups'">
            <label class="description">{{ $t('user.filters.available_territories') }}</label>
            <v-autocomplete
                :value="currentFilters.groups"
                :class="{
                    filtered:
                        currentFilters.groups &&
                        typeof currentFilters.groups !== 'undefined' &&
                        currentFilters.groups &&
                        Object.values(currentFilters.groups).length > 0,
                    changed: JSON.stringify(currentFilters.groups) != JSON.stringify(activeFilters.groups)
                }"
                hide-details
                class="select"
                @input="
                    (inputValue) => {
                        $emit('inputChange', { inputName: 'groups', value: inputValue })
                    }
                "
                v-if="Object.values(optionsGroups)"
                :items="Object.values(optionsGroups)"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="currentFilters.groups ? '' : $t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags v-if="currentFilters.groups" :tags="currentFilters.groups" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>

        <!-- TEMPLATE -->
        <div class="filter">
            <label>{{ $t('supervise.audits.template_audits') }}</label>
            <v-autocomplete
                :class="{
                    filtered: currentFilters.template && Object.values(currentFilters.template).length > 0,
                    changed: JSON.stringify(currentFilters.template) != JSON.stringify(activeFilters.template)
                }"
                :items="Object.values(optionsTemplates)"
                :value="currentFilters.template"
                @input="
                    (templateInputValue) => {
                        $emit('inputChange', { inputName: 'template', value: templateInputValue })
                    }
                "
                :placeholder="$t('assets.filter.filter_by')"
                :no-data-text="$t('supervise.filter.no_results')"
                item-text="name"
                item-value="id"
                hide-details
                class="select"
                multiple
                attach
                dense
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>

                <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters class="options" v-html="item.name"> </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-autocomplete>
            <Tags :tags="currentFilters.template" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>

        <!-- EMPLOYEES -->
        <div class="filter">
            <label>{{ $t('supervise.audits.table_headers.supervised_by') }}</label>
            <v-autocomplete
                :class="{
                    filtered: currentFilters.employees && Object.values(currentFilters.employees).length > 0,
                    changed: JSON.stringify(currentFilters.employees) != JSON.stringify(activeFilters.employees)
                }"
                :items="fullNameEmployee"
                :value="currentFilters.employees"
                @input="
                    (employeesValue) => {
                        $emit('inputChange', { inputName: 'employees', value: employeesValue })
                    }
                "
                :placeholder="$t('assets.filter.filter_by')"
                :no-data-text="$t('supervise.filter.no_results')"
                hide-details
                class="select"
                item-text="fullName"
                item-value="id"
                multiple
                attach
                dense
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags :tags="currentFilters.employees" :nameTag="'fullName'" value="id" :removable="true"></Tags>
        </div>

        <!-- NOTA FINAL -->
        <div class="filter">
            <label>{{ $t('supervise.audits.table_headers.finalNote') }}</label>
            <input
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'finalNote', value: value.target.value })
                    }
                "
                :class="{
                    filtered: currentFilters.finalNote && Object.values(currentFilters.finalNote).length > 0,
                    changed: JSON.stringify(currentFilters.finalNote) != JSON.stringify(activeFilters.finalNote)
                }"
                type="text"
                hide-details
                class="input"
                v-model="currentFilters.finalNote"
            />
            <v-range-slider
                :value="currentFilters.score"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'score', value: value })
                    }
                "
                step="5"
                hide-details
                class="score"
                thumb-size="25"
                thumb-label="always"
            />
        </div>
        <!-- STATUS -->
        <div class="filter">
            <label>{{ $t('supervise.issues.table_headers.state') }}</label>
            <v-autocomplete
                :class="{
                    filtered: currentFilters.status && Object.values(currentFilters.status).length > 0,
                    changed: JSON.stringify(currentFilters.status) != JSON.stringify(activeFilters.status)
                }"
                :items="optionsStates"
                :value="currentFilters.status"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'status', value: value })
                    }
                "
                :placeholder="$t('assets.filter.filter_by')"
                :no-data-text="$t('supervise.filter.no_results')"
                hide-details
                class="select"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags :tags="currentFilters.status" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>
        <!-- ACTION PLAN -->
        <div class="filter">
            <label>{{ $t('nvgt.report.actionPlan_tab') }}</label>
            <v-autocomplete
                :class="{
                    filtered: currentFilters.actionPlan && Object.values(currentFilters.actionPlan).length > 0,
                    changed: JSON.stringify(currentFilters.actionPlan) != JSON.stringify(activeFilters.actionPlan)
                }"
                :items="optionsActionPlans"
                :value="currentFilters.actionPlan"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'actionPlan', value: value })
                    }
                "
                :placeholder="$t('assets.filter.filter_by')"
                :no-data-text="$t('supervise.filter.no_results')"
                hide-details
                class="select"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags :tags="currentFilters.actionPlan" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>
        <!-- DATE -->
        <div class="filter date">
            <label>{{ $t('supervise.checklists.table_headers.date') }}</label>
            <v-select
                :class="{
                    filtered: currentFilters.type_date,
                    changed: JSON.stringify(currentFilters.type_date) != JSON.stringify(activeFilters.type_date)
                }"
                :items="optionsDate"
                :value="currentFilters.type_date"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'type_date', value: value })
                    }
                "
                hide-details
                class="select"
                item-text="name"
                item-value="value"
                dense
                :no-data-text="$t('supervise.filter.no_results')"
            ></v-select>

            <!-- CUSTOM DATE -->
            <div class="custom" v-if="currentFilters.type_date == 'custom'">
                <!-- START DATE -->
                <div class="description">{{ $t('assets.filter.from') }}:</div>
                <input
                    class="date-input start"
                    :value="currentFilters.start_date"
                    @input="
                        (event) => {
                            $emit('inputChange', { inputName: 'start_date', value: event.target.value })
                        }
                    "
                    :max="currentFilters.end_date"
                    type="date"
                />

                <div class="separator"></div>

                <!-- DATE END -->
                <div class="description">{{ $t('assets.filter.to') }}:</div>
                <input
                    class="date-input end"
                    :value="currentFilters.end_date"
                    @input="
                        (event) => {
                            $emit('inputChange', { inputName: 'end_date', value: event.target.value })
                        }
                    "
                    :min="currentFilters.start_date"
                    type="date"
                />
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    name: 'SuperviseAuditsFilterInputs',
    props: {
        currentFilters: { type: Object },
        activeFilters: { type: Object }
    },
    data() {
        return {}
    },
    computed: {
        optionsLocations() {
            return this.$store.getters['filterLocations/getFilterLocation']()
        },
        optionsTemplates() {
            const templates = Object.values(JSON.parse(JSON.stringify(this.$store.getters['audits/getFilterTemplate']())))

            // add inactive at final name of the template
            if (templates) {
                for (var i = 0; i < templates.length; i++) {
                    if (templates[i].status == '-2') {
                        templates[i].name = templates[i].name + ' <span class="inactive-card">' + this.$t('template.inactive') + '</span>'
                    }
                }
            }
            return templates
        },
        optionsEmployees() {
            return this.$store.getters['audits/getFilterEmployees']()
        },
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
        fullNameEmployee() {
            if (this.currentFilters.employees) {
                this.currentFilters.employees = Object.values(this.currentFilters.employees).map((employee) => ({
                    ...employee,
                    fullName: `${employee.name} ${employee.surname}`
                }))
            }
            // Map the optionsEmployees to include full names
            return Object.values(this.optionsEmployees).map((employee) => ({
                ...employee,
                fullName: `${employee.name} ${employee.surname}`
            }))
        },
        optionsSites() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                {
                    value: 'groups',
                    name: i18n.t('user.filters.territories')
                }
            ]
        },
        optionsGroups() {
            return this.$store.getters['filterLocations/getFilterGroups']()
        },
        optionsStates() {
            const arrFilter = Object.values(this.$store.getters['audits/getFilterStates']()).sort((status1, status2) => (status1.type > status2.type ? 1 : -1))
            return arrFilter
        },
        optionsActionPlans() {
            return [
                { id: 1, name: i18n.t('nvgt.t1.status.notStart') },
                { id: 2, name: i18n.t('nvgt.t1.status.current') },
                { id: 3, name: i18n.t('nvgt.t1.status.past') }
            ];
        }
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.filter-items {
    .filter {
        .score {
            margin-left: 6px;
            width: calc(100% - 10px);
            margin-top: 24px;
        }
    }
}
</style>
