<template>
    <div v-if="viewFilters && selectedView" class="view-config-form-container">
        <div class="sidebar-filters">
            <div class="filter-box">
                <button @click="handleClose" class="cancel-btn" :aria-label="$t('button.cancel')"></button>
                <div class="title">{{ title }}</div>

                <!-- SUPERVISE CHECKLISTS FILTER INPUTS -->
                <SuperviseChecklistsFilterInputs
                    v-if="selectedView.tool === 1"
                    :currentFilters="viewFilters"
                    :activeFilters="activeFilters"
                    @inputChange="(params) => updateViewFilters(params)"
                >
                    <!-- View related inputs inserted as SLOT (view Name and Type) -->
                    <ViewConfigInputs
                        :viewName="viewName"
                        :viewType="viewType"
                        :inputNameErrorMsg="inputNameErrorMsg"
                        :userRolLevelIsSevenOrMore="userRolLevelIsSevenOrMore"
                        @inputChange="updateViewConfigInputs"
                        ref="viewConfigInputs"
                    />
                </SuperviseChecklistsFilterInputs>

                <!-- SUPERVISE RECORDS FILTER INPUTS -->
                <SuperviseRecordsFilterInputs
                    v-if="selectedView.tool === 4"
                    :currentFilters="viewFilters"
                    :activeFilters="activeFilters"
                    @inputChange="(params) => updateViewFilters(params)"
                >
                    <!-- View related inputs inserted as SLOT (view Name and Type) -->
                    <ViewConfigInputs
                        :viewName="viewName"
                        :viewType="viewType"
                        :inputNameErrorMsg="inputNameErrorMsg"
                        :userRolLevelIsSevenOrMore="userRolLevelIsSevenOrMore"
                        @inputChange="updateViewConfigInputs"
                        ref="viewConfigInputs"
                    />
                    <!-- View related inputs inserted as SLOT -->
                </SuperviseRecordsFilterInputs>

                <!-- SUPERVISE INCIDENTS FILTER INPUTS -->
                <SuperviseIncidentsFilterInputs
                    v-if="selectedView.tool === 2"
                    :currentFilters="viewFilters"
                    :activeFilters="activeFilters"
                    @inputChange="(params) => updateViewFilters(params)"
                >
                    <!-- View related inputs inserted as SLOT (view Name and Type) -->
                    <ViewConfigInputs
                        :viewName="viewName"
                        :viewType="viewType"
                        :inputNameErrorMsg="inputNameErrorMsg"
                        :userRolLevelIsSevenOrMore="userRolLevelIsSevenOrMore"
                        @inputChange="updateViewConfigInputs"
                        ref="viewConfigInputs"
                    />
                </SuperviseIncidentsFilterInputs>

                <!-- SUPERVISE AUDITS FILTER INPUTS -->
                <SuperviseAuditsFilterInputs
                    v-if="selectedView.tool === 7"
                    :currentFilters="viewFilters"
                    :activeFilters="activeFilters"
                    @inputChange="(params) => updateViewFilters(params)"
                >
                    <!-- View related inputs inserted as SLOT (view Name and Type) -->
                    <ViewConfigInputs
                        :viewName="viewName"
                        :viewType="viewType"
                        :inputNameErrorMsg="inputNameErrorMsg"
                        :userRolLevelIsSevenOrMore="userRolLevelIsSevenOrMore"
                        @inputChange="updateViewConfigInputs"
                        ref="viewConfigInputs"
                    />
                </SuperviseAuditsFilterInputs>

                <!-- SAVE VIEW AND DELETE VIEW BUTTONS -->
                <div class="view-actions">
                    <Button v-if="isEdit" bType="clearFilters" :bLabel="$t('assets.views.delete_view')" @on-button-click="handleDeleteView"></Button>
                    <Button bType="savePrimary" :bLabel="saveViewButtonLabel" @on-button-click="handleSaveView"></Button>
                </div>
            </div>
        </div>
        <div class="table-side"></div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Button from '@/components/ui/Button.vue'
import ViewConfigInputs from './ViewConfigInputs.vue'
import SuperviseChecklistsFilterInputs from '../checklist/supervise/SuperviseChecklistsFilterInputs.vue'
import SuperviseRecordsFilterInputs from '../record/supervise/SuperviseRecordsFilterInputs.vue'
import SuperviseIncidentsFilterInputs from '../issues/supervise/SuperviseIncidentsFilterInputs.vue'
import SuperviseAuditsFilterInputs from '../audit/supervise/SuperviseAuditsFilterInputs.vue'

export default {
    components: {
        Button,
        ViewConfigInputs,
        SuperviseChecklistsFilterInputs,
        SuperviseRecordsFilterInputs,
        SuperviseIncidentsFilterInputs,
        SuperviseAuditsFilterInputs
    },
    props: {
        selectedView: { type: Object },
        isEdit: { type: Boolean }
    },
    data() {
        return {
            viewName: '',
            inputNameErrorMsg: '',
            viewType: 3, // The default should be always 3 ("Only for me")
            viewFilters: {},
            activeFilters: {}
        }
    },
    computed: {
        saveViewButtonLabel() {
            if (this.isEdit) {
                return this.$t('assets.views.save_view')
            } else return this.$t('assets.views.create_view')
        },
        title() {
            if (this.isEdit) {
                return this.$t('assets.views.edit_view')
            } else return this.$t('assets.views.create_view')
        },
        userRolLevelIsSevenOrMore() {
            return this.$store.getters['getLevelUser'] >= 7
        }
    },
    methods: {
        updateViewFilters(params) {
            const { inputName, value } = params

            if (inputName === 'type_date' && value !== 'custom') {
                this.viewFilters.start_date = ''
                this.viewFilters.end_date = ''
            }

            if (inputName === 'complete_date' && value !== 'custom') {
                this.viewFilters.complete_date_start = ''
                this.viewFilters.complete_date_end = ''
            }

            if (inputName === 'sites') {
                this.viewFilters.locations = ''
                this.viewFilters.territories = ''

                // force update in order to display the tags:
                this.viewFilters = { ...this.viewFilters }
            }

            if (value && Array.isArray(value)) {
                this.viewFilters[inputName] = [...value]
            } else {
                this.viewFilters[inputName] = value
            }

            // force update in order to display the tags:
            this.viewFilters = { ...this.viewFilters }
        },

        handleClose() {
            this.$emit('on-cancel-button')
            this.viewFilters = {}
            this.activeFilters = {}
        },

        displaySnackbar() {
            const self = this

            if (self.isEdit) {
                self.$snackbar.open({
                    message: self.$t('assets.views.edited_view_message'),
                    customClass: 'success',
                    duration: 5000
                })
            } else {
                self.$snackbar.open({
                    message: self.$t('assets.views.created_view_message'),
                    customClass: 'success',
                    duration: 5000
                })
            }
        },

        async handleSaveView() {
            // show error message if input name is empty and don´t continue
            if (this.viewName.length == 0) {
                this.inputNameErrorMsg = i18n.t('assets.views.no_name_error_msg')

                this.scrollToShowInputViewNameError()
                return
            }

            this.inputNameErrorMsg = ''

            if (this.isEdit) {
                // HANDLE EDIT A VIEW AND ERRORS

                // editView() calls store action that saves in db
                const response = await this.editView()

                if (!response) {
                    logError('Not response on handleSaveView')
                    return
                }

                if (!response.error && response.data) {
                    await this.$emit('view-created-or-edited', response.data)

                    this.displaySnackbar()

                    this.handleClose()
                } else {
                    if (response.error.code === -5) {
                        this.inputNameErrorMsg = i18n.t('assets.views.duplicated_name_error_msg')
                        this.scrollToShowInputViewNameError()
                    }
                }
            } else {
                // HANDLE CREATE NEW VIEW AND ERRORS

                // createNewView() calls store action that saves in db
                const response = await this.createNewView()

                if (!response) {
                    logError('Not response on handleSaveView')
                    return
                }

                if (!response.error && response.data) {
                    await this.$emit('view-created-or-edited', response.data)

                    this.displaySnackbar()

                    this.handleClose()
                } else {
                    if (response.error.code === -5) {
                        this.inputNameErrorMsg = i18n.t('assets.views.duplicated_name_error_msg')
                        this.scrollToShowInputViewNameError()
                    }
                }
            }
        },

        // calls store action that saves in db
        async editView() {
            const view = {
                name: this.viewName,
                type: this.viewType,
                data: this.viewFilters,
                id: this.selectedView.id
            }

            const selectedViewTool = this.selectedView.tool

            const toolToActionMap = {
                1: 'editSuperviseChecklistsView', // tool 1 = Supervise Checklists
                4: 'editSuperviseRecordsView', // tool 4 = Supervise Records
                2: 'editSuperviseIncidentsView', // tool 2 = Supervise Issues
                7: 'editSuperviseAuditsView' // tool 7 = Supervise Audits
            }

            if (toolToActionMap[selectedViewTool]) {
                const actionName = toolToActionMap[selectedViewTool]

                const response = await this.$store.dispatch(`filtersViews/${actionName}`, view)

                return response
            }
        },

        // calls store action that saves in db
        async createNewView() {
            const view = {
                name: this.viewName,
                type: this.viewType,
                data: this.viewFilters
            }

            const toolToActionMap = {
                1: 'addSuperviseChecklistsView', // tool 1 = Supervise Checklists
                4: 'addSuperviseRecordsView', // tool 4 = Supervise Records
                2: 'addSuperviseIncidentsView', // tool 2 = Supervise Issues
                7: 'addSuperviseAuditsView' // tool 7 = Supervise Audits
            }

            const currentTool = this.$route.meta.tool

            if (toolToActionMap[currentTool]) {
                const actionName = toolToActionMap[currentTool]

                const response = await this.$store.dispatch(`filtersViews/${actionName}`, view)

                return response
            }
        },

        handleDeleteView() {
            const deletePopupData = {
                title: this.$t('assets.views.delete_view_modal_title'),
                message: this.$t('assets.views.delete_view_modal_message', { s: this.selectedView.name }),
                callSave: this.deleteView, // (deleteView calls the store action that deletes in db)
                requireTyping: true
            }

            try {
                this.$popup.delete(deletePopupData)
            } catch (error) {
                logError(error)
            }
        },

        // DELETE VIEW (this one calls the store)
        async deleteView() {
            this.$overlay.show()

            const isDeleted = await this.$store.dispatch('filtersViews/deleteView', this.selectedView)

            if (isDeleted) {
                this.$emit('on-view-deleted')

                this.$snackbar.open({
                    message: this.$t('assets.views.deleted_view_message'),
                    customClass: 'second',
                    duration: 5000
                })

                this.handleClose()

                this.$overlay.close()
            }
        },

        scrollToShowInputViewNameError() {
            const viewNameInput = this.$refs.viewConfigInputs.$refs.viewConfigInputs

            viewNameInput.scrollIntoView({ block: 'start' })
        },

        updateViewConfigInputs(params) {
            const { inputName, value } = params

            if (inputName === 'viewName') {
                this.viewName = value
                this.inputNameErrorMsg = ''
            }

            if (inputName === 'viewType') {
                this.viewType = value
            }
        },

        setViewFilters() {
            const currentTool = this.$route.meta.tool

            const moduleMap = {
                1: 'tasks',
                4: 'records',
                2: 'issues',
                7: 'audits'
            }

            if (moduleMap[currentTool]) {
                const moduleName = moduleMap[currentTool]

                this.viewFilters = structuredClone(this.$store.getters[`${moduleName}/getCurrentFilters`])

                if (this.viewFilters) {
                    this.activeFilters = structuredClone(this.viewFilters)
                }
            }
        }
    },

    watch: {},

    mounted() {
        if (this.isEdit) {
            this.viewName = this.selectedView.name
            this.viewType = this.selectedView.type
        }
        this.setViewFilters()
    }
}
</script>

<style lang="scss" scoped>
.view-config-form-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 30;
    display: flex;

    // gap: 0.5em;
    // padding-right: 24px;
    // padding-left: 24px;

    padding-right: 8px;
    padding-left: 8px;

    .title {
        padding-top: 24px;
        padding-bottom: 16px;
    }

    .cancel-btn {
        position: absolute;
        top: 16px;
        right: 5px;
        width: 35px;
        aspect-ratio: 1/1;
        background-image: img('close_neutro_s90.svg');
        background-size: inherit;
        border: 1px solid $color-neutral-300;
        margin: 0 !important;

        &:hover {
            background-color: $color-neutral-300;
            border: 1px solid $color-neutral-500;
        }
    }

    .sidebar-filters {
        max-width: 100%;
        background-color: $color-neutral-50;
        padding-right: 16px;
        padding-left: 4px;

        .filter-box {
            .filter-items {
                padding-right: 16px;
                padding-bottom: 100px;
                height: calc(100% - 100px);
                // padding-bottom: 0px;
            }
        }
    }

    label {
        margin: 0 !important;
    }

    .table-side {
        width: 100%;
        background-color: $color-neutral-50;
        opacity: 0.8;
    }

    .view-actions {
        width: 100%;
        max-width: 100%;
        position: absolute;
        bottom: 20px;
        left: 0px;

        display: flex;
        flex-direction: column;
        gap: 0.5em;

        padding-right: 0.5em;
        background-color: inherit;
    }

    .error-message {
        color: $color-error-500;
        @include font-size(sm);
    }
}
</style>
