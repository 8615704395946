var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentFilters)?_c('div',{staticClass:"filter-items"},[_vm._t("default"),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.filters.search_by')))]),(Object.values(_vm.optionsSites))?_c('v-select',{staticClass:"select",class:{
                filtered: _vm.currentFilters.sites,
                changed:
                    _vm.currentFilters.sites !== undefined &&
                    _vm.activeFilters.sites !== undefined &&
                    JSON.stringify(_vm.currentFilters.sites) != JSON.stringify(_vm.activeFilters.sites)
            },attrs:{"value":_vm.currentFilters.sites,"hide-details":"","items":Object.values(_vm.optionsSites),"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by')},on:{"input":function (value) {
                    _vm.$emit('inputChange', { inputName: 'sites', value: value })
                }}}):_vm._e()],1),(_vm.currentFilters.sites == 'locations')?_c('div',{staticClass:"sites filter"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_sites')))]),(Object.values(_vm.optionsLocations))?_c('v-autocomplete',{staticClass:"select",class:{
                filtered:
                    _vm.currentFilters.locations && 
                    typeof _vm.currentFilters.locations !== 'undefined' && 
                    Object.values(_vm.currentFilters.locations).length > 0,
                changed:
                    JSON.stringify(_vm.currentFilters.locations) != JSON.stringify(_vm.activeFilters.locations) &&
                    _vm.currentFilters.locations &&
                    Object.values(_vm.currentFilters.locations).length > 0
            },attrs:{"value":_vm.currentFilters.locations,"hide-details":"","items":Object.values(_vm.optionsLocations),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.currentFilters.locations ? '' : _vm.$t('assets.filter.filter_by'),"return-object":""},on:{"input":function (locationsInputValue) {
                    _vm.$emit('inputChange', { inputName: 'locations', value: locationsInputValue })
                }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}}],null,false,257687894)}):_vm._e(),(_vm.currentFilters.locations)?_c('Tags',{attrs:{"tags":_vm.currentFilters.locations,"backImage":'location_inactive_s30.svg',"nameTag":'name',"value":"id","removable":true}}):_vm._e()],1):(_vm.currentFilters.sites == 'groups')?_c('div',{staticClass:"custom filter"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_territories')))]),(Object.values(_vm.optionsGroups))?_c('v-autocomplete',{staticClass:"select",class:{
                filtered:
                    _vm.currentFilters.groups &&
                    typeof _vm.currentFilters.groups !== 'undefined' &&
                    _vm.currentFilters.groups &&
                    Object.values(_vm.currentFilters.groups).length > 0,
                changed: JSON.stringify(_vm.currentFilters.groups) != JSON.stringify(_vm.activeFilters.groups)
            },attrs:{"value":_vm.currentFilters.groups,"hide-details":"","items":Object.values(_vm.optionsGroups),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.currentFilters.groups ? '' : _vm.$t('assets.filter.filter_by'),"return-object":""},on:{"input":function (inputValue) {
                    _vm.$emit('inputChange', { inputName: 'groups', value: inputValue })
                }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}}],null,false,257687894)}):_vm._e(),(_vm.currentFilters.groups)?_c('Tags',{attrs:{"tags":_vm.currentFilters.groups,"nameTag":'name',"value":"id","removable":true}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.records.table_headers.done_by')))]),_c('v-autocomplete',{staticClass:"select",class:{
                filtered: _vm.currentFilters.employees && Object.values(_vm.currentFilters.employees).length > 0,
                changed: JSON.stringify(_vm.currentFilters.employees) != JSON.stringify(_vm.activeFilters.employees)
            },attrs:{"items":_vm.fullNameEmployee,"value":_vm.currentFilters.employees,"placeholder":_vm.$t('assets.filter.filter_by'),"no-data-text":_vm.$t('supervise.filter.no_results'),"hide-details":"","item-text":"fullName","item-value":"id","multiple":"","attach":"","dense":"","return-object":""},on:{"input":function (value) {
                    _vm.$emit('inputChange', { inputName: 'employees', value: value })
                }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}}],null,false,257687894)}),_c('Tags',{attrs:{"tags":_vm.currentFilters.employees,"limit":2,"nameTag":'fullName',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter date"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.date')))]),_c('v-select',{staticClass:"select",class:{
                filtered: _vm.currentFilters.type_date,
                changed: JSON.stringify(_vm.currentFilters.type_date) != JSON.stringify(_vm.activeFilters.type_date)
            },attrs:{"value":_vm.currentFilters.type_date,"items":_vm.optionsDate,"placeholder":_vm.$t('assets.filter.filter_by'),"no-data-text":_vm.$t('supervise.filter.no_results'),"hide-details":"","item-text":"name","item-value":"value","dense":""},on:{"input":function (value) {
                    _vm.$emit('inputChange', { inputName: 'type_date', value: value })
                }}}),(_vm.currentFilters.type_date == 'custom')?_c('div',{staticClass:"custom"},[_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.from'))+":")]),_c('input',{staticClass:"date-input start",attrs:{"max":_vm.currentFilters.end_date,"type":"date"},domProps:{"value":_vm.currentFilters.start_date},on:{"input":function (event) {
                        _vm.$emit('inputChange', { inputName: 'start_date', value: event.target.value })
                    }}}),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.to'))+":")]),_c('input',{staticClass:"date-input end",attrs:{"min":_vm.currentFilters.start_date,"type":"date"},domProps:{"value":_vm.currentFilters.end_date},on:{"input":function (event) {
                        _vm.$emit('inputChange', { inputName: 'end_date', value: event.target.value })
                    }}})]):_vm._e()],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }