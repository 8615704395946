<template>
    <div v-if="views" :class="[$mq, 'dropdown']" ref="dropdownMenuRef">
        <div class="dropdown-button-container">
            <button class="dropdown-button" @click="toggleDropdown">{{ selectedViewName }}</button>
        </div>
        <div class="dropdown-list-container" v-if="isOpen">
            <!-- search input -->
            <input type="search" v-model="searchValue" :placeholder="$t('assets.views.search_view')" class="search-input" />

            <!-- list with no subtitles (filtered views) -->
            <!-- <ul v-if="listWithoutSubtitles" class="dropdown-list no-subtitles">
                <li v-for="view in filteredViews" @click="handleViewSelected(view)">{{ view.name }}</li>
            </ul> -->

            <div v-if="searchValue.length > 0 && noResultsAfterSearch">
                <p class="noViewMsg">{{ $t('assets.views.no_views_found') }}</p>
            </div>

            <!-- default view list (with subtitles) -->
            <ul v-else class="dropdown-list" tabindex="0">
                <!-- Custom "Only for me" views -->
                <template v-if="customEmployeeViews">
                    <h4 class="subtitle">{{ $t('assets.views.custom_by_employee') }}</h4>
                    <li v-for="view in customEmployeeViews" @click="handleViewSelected(view)">{{ view.name }}</li>
                </template>
                <!-- Custom (brand) views -->
                <template v-if="customBrandViews">
                    <h4 class="subtitle">{{ $t('assets.views.custom_by_account') }}</h4>
                    <li v-for="view in customBrandViews" @click="handleViewSelected(view)">{{ view.name }}</li>
                </template>
                <!-- Standard views -->
                <template v-if="standardViews">
                    <h4 class="subtitle">{{ $t('assets.views.standard') }}</h4>
                    <li v-for="view in standardViews" @click="handleViewSelected(view)">{{ view.name }}</li>
                </template>
            </ul>
            <!-- create new view button -->
            <button class="create-view-btn" @click="handleCreateView">{{ $t('assets.views.create_new_view') }}</button>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'ViewsDropdownMenu',
    props: {
        views: Array,
        selectedView: Object || null,
        newFiltersAppliedToAView: Boolean
    },
    data() {
        return {
            isOpen: false,
            searchIsVisible: false,
            // search:
            searchValue: '',
            filteredViews: [],
            debounceTimer: null,
            noResultsAfterSearch: false
        }
    },

    computed: {
        selectedViewName() {
            const noViewAppliedMsg = `- ${i18n.t('assets.views.no_view_applied')} -`

            if (this.selectedView && !this.newFiltersAppliedToAView) {
                return this.selectedView.name
            } else return noViewAppliedMsg
        },

        standardViews() {
            const allStandardViews = this.views.filter((view) => view.type === 1) // 1 = Standard views

            if (this.filteredViews.length > 0) {
                const filteredStandardViews = this.filteredViews.filter((view) => view.type === 1)

                return filteredStandardViews.length > 0 ? filteredStandardViews : null
            } else return allStandardViews.length > 0 ? allStandardViews : null
        },

        customBrandViews() {
            const allCustomBrandViews = this.views.filter((view) => view.type === 2) // 2 = Custom by brand views

            if (this.filteredViews.length > 0) {
                const filteredCustomBrandViews = this.filteredViews.filter((view) => view.type === 2)

                return filteredCustomBrandViews.length > 0 ? filteredCustomBrandViews : null
            } else return allCustomBrandViews.length > 0 ? allCustomBrandViews : null
        },

        customEmployeeViews() {
            const allCustomEmployeeViews = this.views.filter((view) => view.type === 3)

            if (this.filteredViews.length > 0) {
                const filteredCustomEmployeeViews = this.filteredViews.filter((view) => view.type === 3)

                return filteredCustomEmployeeViews.length > 0 ? filteredCustomEmployeeViews : null
            } else return allCustomEmployeeViews.length > 0 ? allCustomEmployeeViews : null
        }

        // listWithoutSubtitles() {
        //     return this.filteredViews.length > 0
        // }
    },

    watch: {
        searchValue(newSearchValue, oldSearchValue) {
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer)
            }

            if (newSearchValue !== '') {
                this.debounceTimer = setTimeout(() => {
                    this.filteredViews = this.filterViews(newSearchValue)
                }, 300)
            } else this.filteredViews = []
        }
    },

    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen
        },

        handleViewSelected(clickedView) {
            this.$emit('on-view-selected', clickedView)

            this.closeDropdown()
        },

        closeDropdown() {
            this.isOpen = false
            this.searchValue = ''
            this.filteredViews = []
        },

        filterViews(value) {
            function normalize(string) {
                return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            }

            const words = normalize(value.toLowerCase()).split(' ')

            const filteredViews = this.views.filter((view) => {
                const viewName = normalize(view.name.toLowerCase())

                return words.every((word) => viewName.includes(word))
            })

            if (filteredViews.length === 0) {
                setTimeout(() => {
                    this.noResultsAfterSearch = true
                }, 600)
            } else this.noResultsAfterSearch = false

            return filteredViews
        },

        handleCreateView() {
            this.isOpen = false
            this.$emit('on-create-view')
        },

        checkClickOutside(e) {
            const dropdown = this.$refs.dropdownMenuRef
            const target = e.target

            if (dropdown !== target && !dropdown.contains(target)) {
                this.closeDropdown()
            }
        }
    },

    created() {
        document.addEventListener('click', this.checkClickOutside)
    },

    destroyed() {
        document.removeEventListener('click', this.checkClickOutside)
    }
}
</script>

<style lang="scss" scoped>
.dropdown {
    position: relative;
    display: inline-block;
    width: 258px;
}

.dropdown-button-container {
    position: relative;
    width: 100%;
}

.dropdown-button {
    position: relative;
    @include font-size('sm');
    color: $color-black;
    background-image: img('down_caret_neutro_s90.svg');
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: 94%;
    background-color: $color-white;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 35px;
    padding: 0px 10px;
    margin: 0 !important;
    min-width: 213px;
    max-width: 213px;
    text-align: start;
    font-family: $text;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 1.2em;
    border: 1px solid $color-neutral-300;
}

.search-input {
    @include font-size('sm');
    font-family: $text;
    color: $color-black;
    border: none;
    width: 180px;
    padding: 0.6em 1em 0;
}

.dropdown-list-container {
    position: absolute;
    top: 100%;
    left: 0;
    max-height: 600px;
    overflow-y: scroll;
    background-color: $color-white;
    list-style: none;
    margin-top: 3px;
    width: 100%;
    z-index: 20;
    border-radius: 5px;
    border: 1px solid $color-neutral-200;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.dropdown-list {
    @include font-size('sm');
    display: flex;
    flex-direction: column;
    padding: 0;
}

.no-subtitles {
    padding-top: 0.5em;
}

.subtitle {
    @include font-size('sm');
    font-family: $text-medium;
    padding-left: 1em;
    padding-top: 1em;
}
.dropdown-list li {
    color: $color-black;
    padding: 0.5em 1em 0.5em 2em;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: $text;
    color: $color-neutral-600;
}

.dropdown-list li:hover {
    background-color: $color-neutral-200;
}

.create-view-btn {
    font-family: $text-medium;
    @include font-size('sm');
    width: 100%;
    text-align: left;
    background-image: img('add_black.svg');
    background-position: 11px center;
    background-size: inherit;
    color: $color-black;
    background-color: $color-white;
    margin: 0px !important;
    padding: 1.5em 1em 1.5em 2em;
    display: flex;
    align-items: center;

    &:hover {
        background-color: $color-neutral-300;
    }
}

.noViewMsg {
    padding: 1.5em 1em 1em 1em;
    font-family: $text;
    color: $color-neutral-600;
    text-align: center;
}

.dropdown {
    &.portrait {
        width: 180px;

        .dropdown-button {
            min-width: 175px;
            max-width: 175px;
        }
    }
}
</style>
